import pkg from '../../package.json'
import { getConfigFileName } from '../../build/getConfigFileName'
import type { GlobEnvConfig } from '#/config'

export function getCommonStoragePrefix() {
    const { VITE_GLOB_APP_SHORT_NAME } = getAppEnvConfig()
    return `${VITE_GLOB_APP_SHORT_NAME}__${getEnv()}`.toUpperCase()
}

// Generate cache key according to version
export function getStorageShortName() {
    return `${getCommonStoragePrefix()}${`__${pkg.version}`}__`.toUpperCase()
}

export function getAppEnvConfig() {
    const ENV_NAME = getConfigFileName(import.meta.env)

    // Get the global configuration (the configuration will be extracted independently when packaging)
    const ENV = (import.meta.env.DEV
        ? (import.meta.env as unknown as GlobEnvConfig)
        : window[ENV_NAME as any]) as unknown as GlobEnvConfig

    const {
        VITE_GLOB_APP_TITLE,
        VITE_GLOB_APP_TITLE_CN,
        VITE_GLOB_API_URL,
        VITE_GLOB_APP_SHORT_NAME,
        VITE_GLOB_UPLOAD_URL,
        VITE_GLOB_PROD_MOCK,
        VITE_GLOB_IMG_URL,
        VITE_ENABLE_ERUDA,
    } = ENV

    return {
        VITE_GLOB_APP_TITLE,
        VITE_GLOB_APP_TITLE_CN,
        VITE_GLOB_API_URL,
        VITE_GLOB_APP_SHORT_NAME,
        VITE_GLOB_UPLOAD_URL,
        VITE_GLOB_PROD_MOCK,
        VITE_GLOB_IMG_URL,
        VITE_ENABLE_ERUDA,
    }
}

/**
 * @description: Development model
 */
export const devMode = 'development'

/**
 * @description: Production mode
 */
export const prodMode = 'production'

/**
 * @description: Get environment variables
 * @returns:
 * @example:
 */
export function getEnv(): string {
    return import.meta.env.MODE
}

/**
 * @description: Is it a development mode
 * @returns:
 * @example:
 */
export function isDevMode(): boolean {
    return import.meta.env.DEV
}

/**
 * @description: Is it a production mode
 * @returns:
 * @example:
 */
export function isProdMode(): boolean {
    return import.meta.env.PROD
}
